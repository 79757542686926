/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
// */
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import moment from "moment-timezone";
import Modbus from "layouts/dashboard/components/Modbus";

function Dashboard() {
  global.fethDetails = false;
  moment.tz.setDefault("Asia/Calcutta");
  return (
    <DashboardLayout>
      <MDBox bgColor="light" borderRadius="10px" border={1} borderColor="dark" mt={-1}>
        {localStorage.getItem("authenticated") === true ? (
          <MDBox>RETRY Authentication</MDBox>
        ) : (
          <Modbus />
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
