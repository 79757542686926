/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable prefer-destructuring */
import { useState } from "react";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import DownloadIcon from "@mui/icons-material/Download";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples

import ExportToXls from "./downloadfile";

// function ShowSelectedDevData() {
//   global.currDevId
//     ? console.log("global.currDevId", global.currDevId)
//     : console.log("Select Form List");

//   const [myData, setMyData] = useState([{}]);
//   const [remoteDataCol, setRemoteDataCol] = useState([{}]);
//   const [remoteTableHeader, setRemoteTableHeader] = useState([{}]);

//   useEffect(() => {
//     TableData.slice(0, 1).map((key, index) => {
//       Object.keys(key).map((subkey) => {
//         if (subkey === "datasets") {
//           //   console.log("DataSet", TableData[index][subkey]);
//           setRemoteDataCol(...TableData[index][subkey]);
//         }
//       });
//     });
//   }, [TableData]);

//   const devKey = Object.keys(remoteDataCol);

//   if (devKey)
//     useEffect(() => {
//       devKey.map((id) => {
//         console.log("remoteDataCol_Keys :", id);
//         setRemoteTableHeader((remoteTableHeader) => [
//           ...remoteTableHeader,
//           {
//             Header: id,
//             accessor: id,
//             align: "center",
//           },
//         ]);
//       });
//     }, [remoteDataCol]);

//   const thisTableHeader = remoteTableHeader.slice(2, remoteTableHeader.length);

//   useEffect(() => {
//     TableData.map((key, index) => {
//       Object.keys(key).map((subkey) => {
//         if (subkey === "datasets") {
//           setMyData(TableData[index][subkey]);
//         }
//       });
//     });
//   }, [TableData]);

//   return (
//     <DataTable
//       table={{ columns: thisTableHeader, rows: myData }}
//       isSorted={false}
//       entriesPerPage={false}
//       showTotalEntries={false}
//       noEndBorder
//     />
//   );
// }

function GenericLogTable({ elDevName, extDevName }) {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [logApi, setLogAPi] = useState("");

  if (elDevName && extDevName) {
    const PrepDownLoad = () => {
      const baseurl = global.baseurl;
      const delimiter = "/";
      const actiontype = "regularmaindata";
      // const apiEndPoint = "/get_data";
      const apiEndPoint = "/get_mbus_data";

      const prepLogApi = baseurl.concat(
        elDevName,
        delimiter,
        extDevName,
        delimiter,
        actiontype,
        delimiter,
        startDate,
        delimiter,
        endDate,
        apiEndPoint
      );
      setLogAPi(prepLogApi);
      // const { data: deviceData, loading, error } = useFetch(logApi);

      // if (loading) return <LoadingSpin />;
      // if (error) return <p>Server Error: {error.message}</p>;

      // if (deviceData) if (deviceData.length) console.log("deviceData::", deviceData);
      // return <p>Work in Progress</p>;
      global.IsDownLoad = true;
    };

    return (
      <MDBox flex flexDirection="row">
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          display="inline-flex"
          alignItems="center"
          align="center"
          gap={1}
        >
          <MDTypography variant="text" fontSize="14px" fontWeight="bold" color="dark">
            Log Report
          </MDTypography>
          <MDTypography variant="text" fontSize="14px" fontWeight="bold" color="dark">
            From
          </MDTypography>
          <MDInput color="dark" type="date" onInput={(e) => setStartDate(e.target.value)} />
          <MDTypography variant="text" fontSize="14px" fontWeight="bold" color="dark">
            To
          </MDTypography>
          <MDInput type="date" onInput={(e) => setEndDate(e.target.value)} />
          {startDate && endDate ? (
            <MDButton
              variant="gradient"
              color="warning"
              onClick={() => PrepDownLoad(elDevName, extDevName, startDate, endDate)}
            >
              Download&nbsp;
              <DownloadIcon>Download</DownloadIcon>
            </MDButton>
          ) : (
            <MDButton variant="gradient" color="info" disabled>
              Donwload&nbsp;
              <DownloadIcon>Download</DownloadIcon>
            </MDButton>
          )}

          {logApi && global.IsDownLoad ? <ExportToXls exportLink={logApi} /> : null}
        </Grid>
      </MDBox>
    );
  }
  return <p> Waiting</p>;
}

GenericLogTable.defaultProps = {
  elDevName: "",
  extDevName: "",
};

GenericLogTable.propTypes = {
  elDevName: PropTypes.string,
  extDevName: PropTypes.string,
};

export default GenericLogTable;
