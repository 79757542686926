import React from "react";

import { Link } from "react-router-dom";
import { Scrollbar } from "react-scrollbars-custom";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import GraphDataList from "layouts/devicedetails/detailedgraphdata";

import PropTypes from "prop-types";
import moment from "moment-timezone";

// function compoTimeStamp(timeStamp, isCompleteList) {
function compoTimeStamp(timeStamp) {
  // const str = timeStamp.replace(" ", "@");

  const str = moment(timeStamp).format("DD-MMM-YY hh:mm a").replace(" ", " @ ");

  if (GraphDataList)
    GraphDataList.forEach((el) => {
      /* eslint-disable-next-line no-param-reassign */
      el.endDate = timeStamp;
    });

  return (
    <MDBox flex align="center" bgColor="black" borderRadius="10px" p={1} m={1}>
      <MDTypography flex color="gold" fontSize="14px">
        {str}
      </MDTypography>
    </MDBox>
  );
}

function resistorGrid(reg) {
  // const mdThreshold = 0.01;
  // const pfThreshold = 0.0;
  // let warnMD = false;
  // let warnPF = false;

  // if (reg.label === "Max Demand kVA" && reg.value > mdThreshold) warnMD = true;
  // if (reg.label === "Power Factor" && reg.value > pfThreshold) warnPF = true;

  // console.log("resistorGrid...", reg);

  return (
    <Grid
      key={reg.port}
      flex
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      border={1}
      m={0.5}
      borderRadius="10px"
      px={1}
    >
      <Grid item>
        <MDTypography
          flex
          variant="button"
          align="center"
          color="dark"
          alignItems="center"
          fontWeight="bold"
        >
          {reg.label}
        </MDTypography>
      </Grid>
      <Grid item>
        <MDBox
        // bgColor={(warnMD || warnPF) && "error"}
        // borderRadius={(warnMD || warnPF) && "10px"}
        // px={(warnMD || warnPF) && 2}
        >
          <Grid flex container direction="row" justifyContent="center" alignItems="center">
            <Grid item>
              <MDTypography flex color="black" fontSize={18} style={{ fontFamily: "DSEG7" }}>
                {Number(reg.value).toFixed(2)}&nbsp;
              </MDTypography>
            </Grid>
            <Grid item>
              <MDTypography flex variant="Caption" color="black" alignItems="center" fontSize={15}>
                {reg.unit}&nbsp;
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
      </Grid>
    </Grid>
  );
}

// function ParamBlock({ src, isCompleteList, elSrc }) {
function ParamBlock({ src, isCompleteList }) {
  const keyParam = [
    "Average Voltage",
    "Average Current",
    "Active Energy",
    "Apparent Energy",
    "Line Frequency",
    "Power Factor",
    "Max Demand kVA",
  ];

  const IsKeyParam = (label) => {
    let ret = false;
    keyParam.forEach((keyValue) => {
      if (label === keyValue) ret = true;
    });
    return ret;
  };

  const handelSubmit = (currDev) => {
    global.currentDev = currDev;
    global.fethDetails = true;
  };

  if (isCompleteList === false)
    return (
      // <Grid item xs={3} ml={-2.5} mt={2.5}>
      <MDBox
        // bgColor="#87cefa"
        flex
        bgColor="grey-200"
        borderRadius="25px"
        border={4}
        borderColor="#b8860b"
        shadow="lg"
        opacity={1}
        p={1}
        m={1}
      >
        <Grid flex container direction="column">
          <MDBox flex>
            <MDBox size="lg" borderRadius="50px" ml={2} mr={1} mb={1} bgColor="dark">
              <MDTypography flex color="white">
                {src.name}
              </MDTypography>
            </MDBox>
            {/* GET TIME STAMP */}
            {compoTimeStamp(src.Captured_Time)}
            {/* GET DATA SET */}
            <MDBox flex m={1}>
              {/* {src.data.map((reg) => (IsKeyParam(reg.label) ? resistorGrid(reg) : null))} */}
              {src.data.map((reg) =>
                IsKeyParam(reg.label) ? (
                  <MDBox key={reg.port}>{resistorGrid(reg)}</MDBox>
                ) : (
                  <MDBox key={reg.port} />
                )
              )}
            </MDBox>
          </MDBox>
        </Grid>
        <Link to="/devicedetails">
          <MDButton
            variant="gradient"
            color="info"
            size="small"
            onClick={() => handelSubmit(src.name)}
          >
            Details
          </MDButton>
        </Link>
      </MDBox>
    );
  return (
    <MDBox
      flex
      justifyContent="space-between"
      alignItems="center"
      // bgColor="#87cefa"
      bgColor="grey-100"
      borderRadius="25px"
      border={4}
      borderColor="#b8860b"
      p={1}
    >
      <Scrollbar style={{ minWidth: 100, height: "47rem" }}>
        <Grid flex container justifyContent="space-around">
          <Grid item xs={11} alignItems="center">
            <MDBox flex bgColor="info" align="center" p={1} m={1} borderRadius="10px">
              <MDTypography flex color="white" align="center">
                {src.name}
              </MDTypography>
              {compoTimeStamp(src.Captured_Time)}
            </MDBox>
          </Grid>
          <Grid item xs={11}>
            <MDBox>
              {/* Populate All Params  */}
              {src.data.map((reg) => (
                <MDBox key={reg.address}>{resistorGrid(reg)}</MDBox>
              ))}
            </MDBox>
          </Grid>
        </Grid>
      </Scrollbar>
    </MDBox>
  );
}

ParamBlock.defaultProps = {
  src: null,
  isCompleteList: false,
  // elSrc: null,
};

ParamBlock.propTypes = {
  src: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  isCompleteList: PropTypes.bool,
  // elSrc: PropTypes.string,
};

export default ParamBlock;
