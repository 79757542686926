import React from "react";

// import Grid from "@mui/material/Grid";
// import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
// import { Scrollbar } from "react-scrollbars-custom";

import ParamBlock from "layouts/dashboard/components/ParamBlock";
import useFetch from "layouts/dashboard/components/CustomRemoteApi/useFetch";
import LoadingSpin from "layouts/dashboard/components/LoadingSpin";
import MDBox from "components/MDBox";
// import GraphDataList from "./detailedgraphdata";

function PopulateParam() {
  const url = global.baseurl;
  const subpath = global.currentPath;
  const delimiter = "/";
  const liveEndPoint = "mbus_live";
  const extdevice = global.currentDev;

  // const setCaptureTime = (cTime) => {
  //   GraphDataList.forEach((el) => {
  //     /* eslint-disable-next-line no-param-reassign */
  //     el.endDate = cTime;
  //   });
  // };

  // for testing the populate device
  // change api to device specific end points

  const liveDataApi = url.concat(subpath, extdevice, delimiter, liveEndPoint);
  // console.log("PopulateParam liveDataApi :", liveDataApi);

  const { data: remoteData, loading, error } = useFetch(liveDataApi);

  if (loading) return <LoadingSpin />;
  if (error) return <p>Server Error: {error.message}</p>;
  // global.fethDetails = false;

  if (remoteData)
    if (remoteData.length >= 1) {
      // Wroking;
      return remoteData.map((elm) => (
        <MDBox key={elm.name}>
          {elm.sources.map((param) => (
            <MDBox key={param.name + param.Captured_Time}>
              <ParamBlock src={param} isCompleteList />
            </MDBox>
          ))}
        </MDBox>
      ));
    }
  return null;
}

export default PopulateParam;
