import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";

export default function devInfoData() {
  return {
    columns: [
      { Header: "Device ID", accessor: "id", align: "center" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "Mobile_1", accessor: "num_1", align: "center" },
      { Header: "Mobile_2", accessor: "num_2", align: "center" },
      { Header: "Power Factor", accessor: "pf", align: "center" },
      { Header: "Max Demand", accessor: "md", align: "center" },
      //   { Header: "action", accessor: "action", align: "center" },
    ],
    rows: [
      {
        id: 13242,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="online" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        num_1: 1234567890,
        num_2: 9876543210,
        pf: 0.95,
        md: 100,
        // action: (
        //   <MDTypography component="a" href="#" color="text">
        //     <Icon>more_vert</Icon>
        //   </MDTypography>
        // ),
      },
      {
        id: 13242,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="online" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        num_1: 1234567890,
        num_2: 9876543210,
        pf: 0.95,
        md: 100,
        // action: (
        //   <MDTypography component="a" href="#" color="text">
        //     <Icon>more_vert</Icon>
        //   </MDTypography>
        // ),
      },
    ],
  };
}
