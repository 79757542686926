/* eslint-disable prefer-destructuring */
import React from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";

import DataTable from "examples/Tables/DataTable";
import useFetch from "layouts/dashboard/components/CustomRemoteApi/useFetch";
import LoadingSpin from "layouts/dashboard/components/LoadingSpin";

function getParamDataApi(elDevName, extDevName, timeStamp) {
  const baseurl = global.baseurl;

  const endDate = moment(timeStamp).format("YYYY-MM-DD");
  const startDate = endDate;
  // let startDate = moment(endDate).subtract(30, "days").format("YYYY-MM-DD");
  // startDate = moment(startDate, "YYYY-MM-DD");

  const delimiter = "/";
  const actiontype = "regularmaindata";
  // const apiEndPoint = "/get_data";
  const apiEndPoint = "/get_mbus_data";

  const remoteAPI = baseurl.concat(
    elDevName,
    delimiter,
    extDevName,
    delimiter,
    actiontype,
    delimiter,
    startDate,
    delimiter,
    endDate,
    apiEndPoint
  );

  return remoteAPI;
}

function ShowTableData({ elDevName, extDevName, timeStamp }) {
  const remoteTableHeader = [{}];

  //   Call API for first dev Data
  if (elDevName && extDevName) {
    const logApi = getParamDataApi(elDevName, extDevName, timeStamp);

    // console.log("logApi: ", logApi);

    const { data: deviceData, loading, error } = useFetch(logApi);

    if (loading) return <LoadingSpin />;
    if (error) return <p>Server Error: {error.message}</p>;

    if (deviceData)
      if (deviceData.length > 0) {
        Object.keys(deviceData[0]).forEach((el) => {
          remoteTableHeader.push({
            Header: el,
            accessor: el,
            align: "center",
          });
        });

        // console.log("Table Data :: ", deviceData);
        const TimeSortedData = [...deviceData].sort((a, b) =>
          a.Captured_Time > b.Captured_Time ? -1 : 1
        );
        // console.log("Table Data Sorted  :: ", TimeSortedData);

        const thisTableHeader = remoteTableHeader.slice(1, remoteTableHeader.length);
        return <DataTable table={{ columns: thisTableHeader, rows: TimeSortedData }} />;
      }
  }
  return <p>Working on it </p>;
}

ShowTableData.defaultProps = {
  elDevName: "",
  extDevName: "",
  timeStamp: "",
};

ShowTableData.propTypes = {
  elDevName: PropTypes.string,
  extDevName: PropTypes.string,
  timeStamp: PropTypes.string,
};

export default ShowTableData;
