/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect, useState } from "react";

// import { Link } from "react-router-dom";

import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
// import MDTypography from "components/MDTypography";

import PropTypes from "prop-types";

// import SlaveDetails from "layouts/dashboard/components/Modbus/SlaveDetails";

import "@fontsource/dseg7";
import "layouts/dashboard/components/Modbus/sevenSegment.css";

import SelectDeviceList from "layouts/dashboard/components/SelectDeviceList";

import useFetch from "layouts/dashboard/components/CustomRemoteApi/useFetch";
import LoadingSpin from "layouts/dashboard/components/LoadingSpin";
import ParamBlock from "layouts/dashboard/components/ParamBlock";
// import DigitalInputStatus from "./dinstatus";
// import { el } from "date-fns/locale";
// import MeterData from "./dummyData";
import DashboardGraph from "./plotdashboardgrpah";

const elMasterDevice = {
  width: "100%",
  height: "450px",
  overflowY: "scroll",
};

/*  eslint-disable react/no-array-index-key */
const renderSlaveDevices = (currDevice) => {
  const result = null;

  // console.log("renderSlaveDevices", currDevice);

  if (currDevice) {
    if (currDevice.length > 0) {
      return currDevice.map((element) =>
        element.sources.map((src) => (
          <Grid
            container
            flex
            flexDirection="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={2}
            key={src.name}
          >
            <Grid item xs mt={1}>
              <ParamBlock src={src} isCompleteList={false} />
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              <MDBox px={2}>
                <DashboardGraph
                  elDev={element.name}
                  extDev={src.name}
                  timeStamp={src.Captured_Time}
                />
              </MDBox>
            </Grid>
          </Grid>
        ))
      );
    }
  }

  return result;
};

function getLiveDataApi(CurrentDevicePath) {
  const url = global.baseurl;
  const usr = localStorage.getItem("currentusr");

  const delimiter = "/";
  const filler = "null";
  const liveEndPoint = "mbus_live";

  const liveDataApi = url.concat(
    usr,
    delimiter,
    CurrentDevicePath,
    filler,
    delimiter,
    liveEndPoint
  );
  global.currentPath = usr + delimiter + CurrentDevicePath;

  return liveDataApi;
}

function MasterDevice({ CurrentDevicePath }) {
  const [liveState, setliveState] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (liveState === 1) {
        setliveState(0);
      }
      if (liveState === 0) {
        setliveState(1);
      }
    }, 30000);
    return () => clearTimeout(timer);
  }, [liveState]);

  if (CurrentDevicePath) {
    const liveApi = getLiveDataApi(CurrentDevicePath);

    // console.log("dbg..CurrentDevicePath...liveApi", CurrentDevicePath, liveApi);

    const { data: currDevice, loading, error } = useFetch(liveApi, liveState);
    if (error) return <p>Server Error: {error.message}</p>;
    // console.log("currDevice : ", currDevice);

    // if (loading) return <LoadingSpin />;

    if (currDevice)
      if (currDevice.length > 0) {
        return (
          <MDBox>
            <MDBox
              flex
              align="center"
              bgColor="light"
              shadow="lg"
              opacity={1}
              p={1}
              style={elMasterDevice}
            >
              <Grid
                container
                flex
                flexDirection="column"
                justifyContent="space-evenly"
                display="inline-flex"
                alignItems="center"
              >
                {loading ? <LoadingSpin /> : renderSlaveDevices(currDevice)}
              </Grid>
            </MDBox>
            {/* {currDevice && <DigitalInputStatus currDevice={currDevice} />} */}
            {/* {global.fethDetails === true ? <SlaveDetails /> : null} */}

            {currDevice ? <SelectDeviceList usrDeviceList={currDevice} /> : null}
          </MDBox>
        );
      }
    // return <LoadingSpin />;
  }
  return null;
}

MasterDevice.defaultProps = {
  CurrentDevicePath: "",
};

MasterDevice.propTypes = {
  CurrentDevicePath: PropTypes.string,
};

export default MasterDevice;
