/* eslint-disable prefer-destructuring */
import React from "react";
import MDBox from "components/MDBox";

import PropTypes from "prop-types";
import moment from "moment-timezone";

import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";

import useFetch from "layouts/dashboard/components/CustomRemoteApi/useFetch";
import LoadingSpin from "layouts/dashboard/components/LoadingSpin";

function getParamDataApi(elDevName, extDevName, timeStamp) {
  const baseurl = global.baseurl;
  const endDate = moment(timeStamp).format("YYYY-MM-DD");
  const startDate = endDate;
  const delimiter = "/";
  const actiontype = "hourlyavgvoltage";
  // const apiEndPoint = "/get_data";
  const apiEndPoint = "/get_mbus_data";

  // console.log("getParamDataApi:: moment ", endDate);

  const remoteAPI = baseurl.concat(
    elDevName,
    delimiter,
    extDevName,
    delimiter,
    actiontype,
    delimiter,
    startDate,
    delimiter,
    endDate,
    apiEndPoint
  );

  return remoteAPI;
}

function dashboardGraph({ elDev, extDev, timeStamp }) {
  //   console.log("DashboardGraph :: ", elDev, extDev);
  const graphLable = [];
  const graphData = [];
  let TotalValue = 0;

  const extDeviveList = getParamDataApi(elDev, extDev, timeStamp);
  const { data: deviceData, loading, error } = useFetch(extDeviveList);

  if (loading) return <LoadingSpin />;
  if (error) return <p>Server Error: {error.message}</p>;

  if (deviceData)
    if (deviceData.length > 0) {
      // console.log("currDevice", deviceData, deviceData.length);
      deviceData.forEach((el) => {
        // graphLable.push(el.Captured_Time);
        graphLable.push(moment(el.Captured_Time).format("MMM-DD hh:mm a"));
        graphData.push(el.value);
        TotalValue += Number(el.value);
      });

      return (
        <MDBox border={1} borderColor="dark" borderRadius="10px" mt={2} mb={2}>
          <VerticalBarChart
            height="22rem"
            icon={{ color: "info", component: "bolt" }}
            title="Energy Consumption Daily"
            description={moment(timeStamp).format("DD-MMM-YYYY")}
            valueTotal={TotalValue.toFixed(2)}
            chart={{
              labels: graphLable,
              datasets: [
                {
                  label: "KVAh",
                  color: "info",
                  data: graphData,
                },
              ],
            }}
          />
        </MDBox>
      );
    }
  return null;
}

getParamDataApi.defaultProps = {
  elDevName: "",
  extDevName: "",
  timeStamp: "",
};

getParamDataApi.propTypes = {
  elDevName: PropTypes.string,
  extDevName: PropTypes.string,
  timeStamp: PropTypes.string,
};

dashboardGraph.defaultProps = {
  elDev: "",
  extDev: "",
  timeStamp: null,
};

dashboardGraph.propTypes = {
  elDev: PropTypes.string,
  extDev: PropTypes.string,
  timeStamp: PropTypes.string,
};

export default dashboardGraph;
