import React from "react";
import PropTypes from "prop-types";
import * as XLSX from "xlsx";

import useFetch from "layouts/dashboard/components/CustomRemoteApi/useFetch";
import LoadingSpin from "layouts/dashboard/components/LoadingSpin";
import { Alert } from "@mui/material";

const getFileName = (exportLink) => {
  const delimiter = "/";
  const spacer = "_";
  const extn = ".xlsx";
  const defaultName = "Report_";
  const splitName = exportLink.split(delimiter);
  //   console.log("splitName ::", splitName[6].trim(), splitName[8], splitName[9]);
  const fileName = defaultName
    .concat(splitName[5], spacer, splitName[6], spacer, splitName[8], spacer, splitName[9], extn)
    .replace(/\s+/g, "");

  return fileName;
};

function ExportToXls({ exportLink }) {
  //   console.log("exportLink ::", exportLink);

  const { data: deviceData, loading, error } = useFetch(exportLink);
  if (loading) return <LoadingSpin />;
  if (error) return <p>Server Error: {error.message}</p>;

  if (deviceData)
    if (deviceData.length > 0) {
      //   console.log("fileName :: ", fileName);
      const fileName = getFileName(exportLink);

      const workSheet = XLSX.utils.json_to_sheet(deviceData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Report");
      // Buffer
      /* eslint-disable no-unused-vars */
      const buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      // Binary string
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      // Download
      XLSX.writeFile(workBook, fileName);

      global.IsDownLoad = false;
      return <Alert onClose={() => {}}>Download Complete!</Alert>;
    }
  return <LoadingSpin />;
}

ExportToXls.defaultProps = {
  exportLink: "",
};

ExportToXls.propTypes = {
  exportLink: PropTypes.string,
};

export default ExportToXls;
