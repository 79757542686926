/* eslint-disable prefer-destructuring */
import React, { useState } from "react";

import Grid from "@mui/material/Grid";

import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";

import useFetch from "layouts/dashboard/components/CustomRemoteApi/useFetch";
import LoadingSpin from "layouts/dashboard/components/LoadingSpin";

import moment from "moment-timezone";

import GraphDataList from "./detailedgraphdata";
// import SelectGraphParam from "./selectgraphparam";

const createRemoteAPI = (element, index) => {
  let remoteAPI = null;
  const baseurl = global.baseurl;
  const delimiter = "/";
  // const liveEndPoint = "get_data";
  const liveEndPoint = "get_mbus_data";

  if (global.currentDev && global.currentPath) {
    const extdevice = global.currentDev;
    const currELDevice = global.currentPath.split(delimiter);

    let endDate = moment(element.endDate).format("YYYY-MM-DD");
    endDate = moment(endDate, "YYYY-MM-DD");

    let timewindow = 0;
    switch (element.timespan) {
      case "hourly":
        timewindow = 0;
        break;
      case "daily":
        timewindow = 30;
        break;
      case "monthly":
        timewindow = 365;
        break;
      default:
        timewindow = 0;
    }
    let startDate = moment(endDate).subtract(timewindow, "days").format("YYYY-MM-DD");
    startDate = moment(startDate, "YYYY-MM-DD");
    GraphDataList[index].startDate = startDate;

    const currApitype = element.timespan.concat(element.title);

    remoteAPI = baseurl.concat(
      currELDevice[3],
      delimiter,
      extdevice,
      delimiter,
      currApitype,
      delimiter,
      /* eslint-disable-next-line no-underscore-dangle */
      startDate._i,
      delimiter,
      /* eslint-disable-next-line no-underscore-dangle */
      endDate._i,
      delimiter,
      liveEndPoint
    );
  }
  // console.log("remoteAPI :: ", remoteAPI);

  return remoteAPI;
};

function PoltGraphGrid(index) {
  const chartParam = GraphDataList[index];

  if (chartParam)
    if (chartParam.label && chartParam.dataPoint) {
      // console.log("chartParam.title :: ", chartParam.title);
      if (chartParam.title === "linefrequency" || chartParam.title === "powerfactor") {
        return (
          <DefaultLineChart
            title={chartParam.graphTitle}
            // description={chartParam.description}
            chart={{
              labels: chartParam.label,
              datasets: [
                {
                  label: "value",
                  // LINE COLOR
                  color: "warning",
                  data: chartParam.dataPoint,
                },
              ],
            }}
          />
        );
      }
      return (
        <ReportsBarChart
          color="#f5f5f5"
          title={chartParam.graphTitle}
          // description={chartParam.description}
          chart={{
            labels: chartParam.label,
            datasets: {
              data: chartParam.dataPoint,
            },
          }}
        />
      );
    }
}

// function DisplayGraph(index, refresh) {
function DisplayGraph(index) {
  if (GraphDataList[index].dataPoint) {
    if (index < 4)
      return (
        <Grid key={index} item xs={6} border={1} borderRadius="10px">
          {/* <SelectGraphParam index={index} refresh={refresh} /> */}
          {PoltGraphGrid(index)}
        </Grid>
      );
    if (index >= 4)
      return (
        <Grid
          key={index}
          item
          justifyContent="flex-start"
          xs={16}
          ml="-18.5rem"
          border={1}
          borderRadius="10px"
        >
          {/* <SelectGraphParam index={index} refresh={refresh} /> */}
          {PoltGraphGrid(index)}
        </Grid>
      );
  }
}

function PopulateGraph() {
  // eslint-disable-next-line no-unused-vars
  const [liveState, setliveState] = useState(-1);

  let defDataListComplete = 0;

  const refresh = (index) => {
    setliveState(index);
  };

  const PrepDefaultGraph = async () => {
    defDataListComplete = 0;

    GraphDataList.forEach(async (element, index) => {
      // const actiontype = deftimeline.concat(element.title);

      const remoteAPI = createRemoteAPI(element, index);
      const label = [];
      const dataPoint = [];
      // console.log("PrepDefaultGraph remoteAPI Fetch Data ::", remoteAPI, element);

      const { data, loading, error } = useFetch(remoteAPI);
      if (loading) return <LoadingSpin />;
      if (error) return <p>Server Error: {error.message}</p>;
      // console.log("prepDefaultgraph Response Data :: ", data);
      if (data)
        if (data.length >= 1) {
          // console.log("prepDefaultgraph Response Data :: ", data);
          data.forEach((el) => {
            // label.push(el.Captured_Time);
            label.push(moment(el.Captured_Time).format("MMM-DD hh:mm a"));

            dataPoint.push(el.value);
          });
          /* eslint-disable no-param-reassign */
          element.dataPoint = dataPoint;
          element.label = label;
          /* eslint-enable no-param-reassign */
          defDataListComplete += 1;
        }
      return data;
    });
    return null;
  };

  if (defDataListComplete === 0) {
    PrepDefaultGraph();
  }

  function asyncWarpper(index) {
    if (GraphDataList[index].dataPoint) return DisplayGraph(index, refresh);
    return <LoadingSpin />;
  }

  return (
    <Grid flex container direction="row">
      {GraphDataList.map((el, index) => asyncWarpper(index))}
    </Grid>
  );
}

export default PopulateGraph;
