export default [
  {
    title: "avgvoltage",
    graphTitle: "Avg. Voltage Vs Hrs.",
    description: null,
    label: [],
    dataPoint: [],
    phasecount: 3,
    timespan: "hourly",
    startDate: "",
    endDate: "",
  },
  {
    title: "powerfactor",
    graphTitle: "Power Factor Vs Hrs.",
    description: null,
    label: [],
    dataPoint: [],
    phasecount: 3,
    timespan: "hourly",
    startDate: "",
    endDate: "",
  },
  {
    title: "avgcurrent",
    graphTitle: "Avg. Current Vs Hrs.",
    description: null,
    label: [],
    dataPoint: [],
    phasecount: 3,
    timespan: "hourly",
    startDate: "",
    endDate: "",
  },
  {
    title: "linefrequency",
    graphTitle: "Line Fq Vs Hrs.",
    description: null,
    label: [],
    dataPoint: [],
    phasecount: 3,
    timespan: "hourly",
    startDate: "",
    endDate: "",
  },
  // {
  //   title: "powerconsumed",
  //   graphTitle: "Unit Consumed Vs Date",
  //   description: null,
  //   label: [],
  //   dataPoint: [],
  //   phasecount: 3,
  //   timespan: "daily",
  //   startDate: "",
  //   endDate: "",
  // },
  // {
  //   title: "powerdemand",
  //   graphTitle: "Power Demand(kw) Vs Date",
  //   description: null,
  //   label: [],
  //   dataPoint: [],
  //   phasecount: 3,
  //   timespan: "daily",
  //   startDate: "",
  //   endDate: "",
  // },
];
