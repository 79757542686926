/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import typography from "assets/theme/base/typography";

function configs(labels, datasets) {
  // return {
  //   type: "bar",
  //   data: {
  //     labels,
  //     datasets: [
  //       {
  //         label: datasets.label,
  //         tension: 0.4,
  //         borderWidth: 0,
  //         borderRadius: 4,
  //         borderSkipped: false,
  //         // backgroundColor: "rgba(255, 255, 255, 0.8)",
  //         backgroundColor: "#ff9900",
  //         data: datasets.data,
  //         maxBarThickness: 6,
  //       },
  //     ],
  //   },
  //   options: {
  //     responsive: true,
  //     maintainAspectRatio: true,
  //     plugins: {
  //       legend: {
  //         display: false,
  //       },
  //     },
  //     // interaction: {
  //     //   intersect: false,
  //     //   mode: "index",
  //     // },
  //     scales: {
  //       y: {
  //         stacked: true,
  //         grid: {
  //           drawBorder: true,
  //           display: true,
  //           drawOnChartArea: true,
  //           drawTicks: false,
  //           borderDash: [5, 5],
  //           // color: "rgba(255, 255, 255, .2)",
  //           color: "dark",
  //         },
  //         ticks: {
  //           suggestedMin: 0,
  //           suggestedMax: 500,
  //           beginAtZero: true,
  //           padding: 10,
  //           font: {
  //             size: 14,
  //             weight: 300,
  //             family: "Roboto",
  //             style: "normal",
  //             lineHeight: 2,
  //           },
  //           // color: "#fff",
  //           color: "dark",
  //         },
  //       },
  //       x: {
  //         stacked: true,
  //         grid: {
  //           drawBorder: false,
  //           display: true,
  //           drawOnChartArea: true,
  //           drawTicks: false,
  //           borderDash: [5, 5],
  //           // color: "rgba(255, 255, 255, .2)",
  //           color: "dark",
  //         },
  //         ticks: {
  //           display: true,
  //           // color: "#f8f9fa",
  //           color: "dark",
  //           padding: 10,
  //           font: {
  //             size: 14,
  //             weight: 300,
  //             family: "Roboto",
  //             style: "normal",
  //             lineHeight: 2,
  //           },
  //         },
  //       },
  //     },
  //   },
  // };
  return {
    data: {
      labels,
      datasets: [
        {
          label: datasets.label,
          data: datasets.data,
          // BAR COLOR
          backgroundColor: "#ff9900",
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          display: true,
          color: "warning",
          formatter: Math.round,
          anchor: "end",
          offset: -20,
          align: "start",
        },
        legend: {
          display: false,
        },
      },
      scales: {
        y: {
          stacked: true,
          grid: {
            suggestedMax: 500,
            drawBorder: false,
            display: true,
            drawOnChartArea: true,
            drawTicks: false,
            borderDash: [5, 5],
          },
          ticks: {
            display: true,
            padding: 10,
            color: "#9ca2b7",
            font: {
              size: 11,
              family: typography.fontFamily,
              style: "normal",
              lineHeight: 2,
            },
          },
        },
        x: {
          stacked: true,
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: true,
            drawTicks: true,
          },
          ticks: {
            display: true,
            color: "#9ca2b7",
            padding: 10,
            font: {
              size: 11,
              family: typography.fontFamily,
              style: "normal",
              lineHeight: 2,
            },
          },
        },
      },
    },
  };
}

export default configs;
