/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import MDBox from "components/MDBox";
import { RotatingLines } from "react-loader-spinner";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

function LoadingSpin() {
  return (
    // <DashboardLayout>
    <MDBox align="center">
      <RotatingLines
        strokeColor="grey"
        strokeWidth="5"
        animationDuration="0.75"
        width="96"
        visible
      />
    </MDBox>
    // </DashboardLayout>
  );
}

export default LoadingSpin;
