/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// import  from "react";
// import { useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MasterDevice from "layouts/dashboard/components/Modbus/MasterDevice";
// import useFetch from "layouts/dashboard/components/CustomRemoteApi/useFetch";
// import LoadingSpin from "layouts/dashboard/components/LoadingSpin";

function Modbus() {
  const currDevPath = localStorage.getItem("devLocation");
  const delimiter = "/";
  if (currDevPath) {
    const url = currDevPath.concat(
      delimiter,
      localStorage.getItem("devSubStation"),
      delimiter,
      localStorage.getItem("devID"),
      delimiter
    );
    return url && <MasterDevice CurrentDevicePath={url} />;
  }
  return (
    <MDBox>
      <MDTypography>Select Device From Device Tree</MDTypography>
    </MDBox>
  );
}
// function Modbus() {
//   let defDevicePath = null;
//   if (global.selected === true) {
//     defDevicePath = global.currentPath;
//     global.selected = false;
//   } else {
//     const [defDeviceTree, setDefaultDeviceTree] = useState(null);
//     const url = global.baseurl;
//     const usr = localStorage.getItem("currentusr");
//     const delimiter = "/";
//     const filler = "null";
//     const endPoint = "get_user_resource_tree";

//     const resourceTreeApi = url.concat(usr, delimiter, filler, delimiter, endPoint);

//     // GET DATA For Default Device...i.e. end device from the tree
//     const { data, loading, error } = useFetch(resourceTreeApi);

//     if (loading) return <LoadingSpin />;
//     if (error) return <p>Server Error: {error.message}</p>;

//     if (data)
//       if (data.length >= 1 && defDeviceTree === null) {
//         setDefaultDeviceTree(data);
//       }

//     // Get Default Device Name

//     if (defDeviceTree) {
//       let isLeafNode = false;
//       // console.log("defDeviceTree :: ", defDeviceTree);
//       let defaultDev = null;
//       defDeviceTree.forEach((location) => {
//         defaultDev = location.name + delimiter;
//         location.substation.forEach((sub) => {
//           defaultDev += sub.name + delimiter;
//           sub.devices.forEach((dev) => {
//             defaultDev += dev.name + delimiter;
//             // console.log("defDeviceTree defaultDev :: ", defaultDev);
//             if (isLeafNode === false) {
//               isLeafNode = true;
//               defDevicePath = defaultDev;
//             }
//           });
//         });
//       });
//     }
//   }
//   // console.log("SELECTED DEVICE ", defDevicePath);

//   return defDevicePath && <MasterDevice CurrentDevicePath={defDevicePath} />;
// }

export default Modbus;
