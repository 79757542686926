/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// @mui icons

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
// import RouteToDashBoard from "layouts/authentication/sign-in-to-dashboard";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import Axios from "axios";

// import ParseDeviceTree from "../devicetree";

const validateNew = async (username, password) => {
  const url = global.baseurl;
  const delimiter = "/";
  const endPoint = "credentials";
  let authentication = false;
  let errResponse = "Error : ";

  // console.log("validateNew username, password ", username, password);
  const credentialsApi = url.concat(username, delimiter, password, delimiter, endPoint);

  // console.log("credentialsApi ", credentialsApi);

  // const { data: response } = ;
  await Axios.get(credentialsApi)
    .then((response) => {
      if (response.data[0].message === "OK") {
        authentication = true;
        // console.log("response....", response);
        localStorage.setItem("currentusr", username);
        localStorage.setItem("usrRole", response.data[0].role);
        localStorage.setItem("customer", response.data[0].customer);
      } else {
        localStorage.setItem("currentusr", null);
        localStorage.setItem("usrRole", null);
        localStorage.setItem("customer", null);
      }
    })
    .catch((err) => {
      // console.log("...err...", err, typeof err);
      if (typeof err === "object") {
        errResponse = errResponse.concat(err.response.data.message);
        localStorage.setItem("currentusr", null);
        localStorage.setItem("usrRole", null);
        localStorage.setItem("customer", null);
      }
    });

  return { authentication, errResponse };
};

function Basic() {
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [authenticated, setauthenticated] = useState(false);
  const [errMsg, seterrorMsg] = useState("");

  const handleSubmitNew = async (uname, pwd) => {
    localStorage.clear();
    const { authentication, errResponse } = await validateNew(uname, pwd);
    setauthenticated(authentication);
    seterrorMsg(errResponse);
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                fullWidth
                onChange={(e) => setusername(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                onChange={(e) => setpassword(e.target.value)}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                onClick={() => handleSubmitNew(username, password)}
                fullWidth
              >
                sign in
              </MDButton>
            </MDBox>
            {errMsg && (
              <MDTypography
                variant="h6"
                fontSize="bold"
                color="error"
                justifyContent="center"
                alignItems="center"
              >
                {errMsg}
              </MDTypography>
            )}
            {authenticated && (
              <Routes>
                <Route path="*" element={<Navigate to="/devicetree/*" />} />
              </Routes>
            )}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
