import { useState, useEffect } from "react";
import Axios from "axios";

const useFetch = (url, refresh) => {
  if (url) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // console.log("Remote API : ", url);

    useEffect(async () => {
      setLoading(true);
      // console.log("Remote API Req : ", url, refresh, loading);
      await Axios.get(url)
        .then((response) => {
          // console.log("API RESPONSE", response);
          setData(response.data);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }, [url, refresh]);

    // console.log("Remote API Result : ", data, loading);
    // return data;
    return { data, loading, error };
  }
  return null;
};

export default useFetch;
