/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Bar } from "react-chartjs-2";

// @mui material components
// import Card from "@mui/material/Card";
// import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// ReportsBarChart configurations
import configs from "examples/Charts/BarCharts/ReportsBarChart/configs";

// function ReportsBarChart({ color, title, description, date, chart }) {
function ReportsBarChart({ color, title, chart }) {
  const { data, options } = configs(chart.labels || [], chart.datasets || {});

  return (
    <MDBox bgColor={color} m={1} borderRadius="10px">
      <MDBox pt={1} px={1} align="center">
        <MDTypography variant="h6" textTransform="capitalize">
          {title}
        </MDTypography>
      </MDBox>
      <MDBox padding="1rem" bgColor={color}>
        {useMemo(
          () => (
            <MDBox variant="gradient" borderRadius="lg" height="19rem">
              <Bar data={data} options={options} />
            </MDBox>
          ),
          [chart, color]
        )}
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of ReportsBarChart
ReportsBarChart.defaultProps = {
  color: "steelblue",
  title: "",
  // date: null,
  // description: "",
};

// Typechecking props for the ReportsBarChart
ReportsBarChart.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "steelblue",
    "white",
    "#f5f5f5",
  ]),
  title: PropTypes.string,
  // description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  // date: PropTypes.string,
  chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
};

export default ReportsBarChart;
