import { useMemo } from "react";
import PropTypes from "prop-types";
import { MaterialReactTable } from "material-react-table";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import { Link } from "react-router-dom";

const handleSubmitNew = (row) => {
  // console.log("row Data : ", row.original);

  localStorage.setItem("devLocation", row.original.Location);
  localStorage.setItem("devSubStation", row.original.Area);
  localStorage.setItem("devName", row.original.DeviceName);
  localStorage.setItem("devID", row.original.DeviceID);
};

function DeviceTableList({ column, data }) {
  // console.log("column, data", column, data);
  const customerName = localStorage.getItem("customer");

  // const [tableData, setTableData] = useState(() => data);

  const tableData = data;

  const columns = useMemo(() => column, []);
  // console.log("MEMO Col", columns);

  // const handleSaveRow = async ({ exitEditingMode, row, values }) => {
  //   // if using flat data and simple accessorKeys/ids, you can just do a simple assignment here.
  //   tableData[row.index] = values;
  //   // send/receive api updates here
  //   setTableData([...tableData]);
  //   exitEditingMode(); // required to exit editing mode
  // };

  // return <MDTypography> {customerName} Work In Progress</MDTypography>;
  return (
    <MaterialReactTable
      // enableTopToolbar={false} //hide top toolbar
      renderTopToolbarCustomActions={() => (
        <MDTypography
          variant="h6"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          // ml={10}
        >
          {customerName}
        </MDTypography>
      )}
      // enableDensityToggle={false}
      initialState={{ density: "compact" }}
      columns={columns}
      data={tableData}
      // defaultColumn={{
      //   minSize: 20, //allow columns to get smaller than default
      //   maxSize: 40, //allow columns to get larger than default
      //   size: 30, //make columns wider by default
      // }}
      // editingMode="modal"
      // enableEditing
      // onEditingRowSave={handleSaveRow}
      positionActionsColumn="last"
      enableRowActions
      renderRowActions={({ row }) => (
        <MDBox justifyContent="center" alignItems="center">
          <Link to="/dashboard">
            <MDButton
              variant="gradient"
              color="info"
              iconOnly
              circular
              size="small"
              onClick={() => handleSubmitNew(row)}
            >
              <Icon>dashboard</Icon>
            </MDButton>
          </Link>
        </MDBox>
      )}
      // muiTopToolbarProps={{
      //   sx: {
      //     backgroundColor: "#e0ffff",
      //     borderRadius: "10px",
      //     display: "flex",
      //     justifyContent: "space-between",
      //     alignItems: "center",
      //     m: 1,
      //   },
      // }}
      // muiBottomToolbarProps={{
      //   sx: {
      //     backgroundColor: "#f5f5dc",
      //     borderRadius: "10px",
      //     display: "flex",
      //     justifyContent: "space-between",
      //     alignItems: "center",
      //     m: 1,
      //   },
      // }}
      muiTableBodyProps={{
        sx: {
          // stripe the rows, make odd rows a darker color
          "& tr:nth-of-type(even)": {
            backgroundColor: "#e0ffff",
          },
          "& tr:nth-of-type(odd)": {
            backgroundColor: "#f5f5dc",
          },
        },
      }}
      // muiTableProps={{
      //   sx: {
      //     border: "1px solid rgba(81, 81, 81, 1)",
      //     borderRadius: "10px",
      //     m: 1,
      //     p: 1,
      //     backgroundColor: "#000000",
      //     tableLayout: "fixed",
      //   },
      // }}
      muiTableHeadCellProps={{
        sx: {
          border: "1px solid rgba(81, 81, 81, 1)",
          fontSize: "12px",
          backgroundColor: "#4682b4",
          color: "#ffffff",
          align: "center",
          // variant: "gradient",
        },
      }}
      muiTableBodyCellProps={{
        sx: {
          border: "1px solid rgba(81, 81, 81, 1)",
          fontSize: "12px",
          fontWeight: "bold",
          textAlign: "center",
        },
      }}
    />
  );
}

DeviceTableList.defaultProps = {
  //   customerName: "",
  column: "",
  data: "",
};

DeviceTableList.propTypes = {
  //   customerName: PropTypes.string,
  column: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default DeviceTableList;
