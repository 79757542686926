/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// @mui icons
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/profile/components/Header";

// Data

// Images
import energyPortal from "assets/images/energyportal.jpg";

function Overview() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox mt={5} mb={3}>
          <Grid container justifyContent="space-evenly" spacing={1}>
            <Grid item xs={12} md={6} xl={4}>
              <MDBox borderRadius="25px" border={4} borderColor="#b8860b">
                <ProfileInfoCard
                  title="profile information"
                  description="Adroit Control Engineers"
                  info={{
                    fullName: "Rajesh Gupta",
                    mobile: "+9198910xxxx",
                    email: "rgupta@adroitcontrol.com",
                    location: "Faridabad",
                  }}
                  action={{ route: "", tooltip: "Edit Profile" }}
                  shadow={false}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
              <DefaultProjectCard
                image={energyPortal}
                label="project #2"
                title="Smart Meters"
                description="Remote Monitoring and Control"
                action={{
                  type: "internal",
                  route: "/dashboard",
                  color: "info",
                  label: "view project",
                }}
              />
            </Grid>
          </Grid>
        </MDBox>
      </Header>
    </DashboardLayout>
  );
}

export default Overview;
