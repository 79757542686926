/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react";

// @mui material components

// Material Dashboard 2 React components
import Grid from "@mui/material/Grid";
// import MenuItem from "@mui/material/MenuItem";
// Material Dashboard 2 React example components

import MDBox from "components/MDBox";
// import MDInput from "components/MDInput";
import PropTypes from "prop-types";
import GenericLogTable from "layouts/dashboard/components/GenericLogTable";
import LoadingSpin from "layouts/dashboard/components/LoadingSpin";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

import ShowTableData from "./showtable";

function SelectDeviceList({ usrDeviceList }) {
  let elDevName = "";
  const [currDevId, setCurrDevId] = useState("");
  const extDevName = [];
  const extDevTimeStamp = [];
  // console.log("SelectDeviceList :: ", usrDeviceList);

  if (usrDeviceList) {
    usrDeviceList.forEach((el) => {
      // console.log("SelectDeviceList :: ", el.name);
      elDevName = el.name;
      el.sources.forEach((element) => {
        // console.log("SelectDeviceList :: ", element.name);
        extDevName.push(element.name);
        extDevTimeStamp.push(element.Captured_Time);
      });
    });
  }

  useEffect(() => {
    setCurrDevId(extDevName[0]);
  }, [currDevId]);

  const handleChange = (e) => {
    setCurrDevId(e.target.value);
  };

  if (elDevName && extDevName) {
    return (
      <MDBox flex bgColor="light" flexDirection="row" shadow="lg" opacity={1} mt={1}>
        <MDBox bgColor="light" borderRadius="10px">
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            display="inline-flex"
            alignItems="center"
            align="center"
            mt={2}
          >
            {/* <MDBox minWidth="25%"> */}
            {/* <MDInput
                size="large"
                select
                value={currDevId}
                fontWeight="bold"
                label="Select Device ID *"
                InputProps={{
                  classes: { root: "select-input-styles" },
                }}
                onChange={handleChange}
                fullWidth
                align="center"
                autoFocus
              >
                {extDevName.map((dev) => (
                  <MenuItem key={dev} value={dev}>
                    {dev}
                  </MenuItem>
                ))}
              </MDInput> */}
            <MDBox
              minWidth="25%"
              bgColor="warning"
              borderRadius="10px"
              p={0.5}
              flex
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              fontSize="12px"
              fontWeight="bold"
            >
              <Dropdown
                options={extDevName}
                onChange={handleChange}
                value={currDevId}
                placeholder="Select an option"
              />
            </MDBox>
            {/* </MDBox> */}
            <MDBox minWidth="65%" border={1} borderRadius="10px">
              <GenericLogTable elDevName={elDevName} extDevName={currDevId} />
            </MDBox>
          </Grid>
          <MDBox m={2} border={1} borderRadius="10px">
            <ShowTableData
              elDevName={elDevName}
              extDevName={currDevId}
              timeStamp={extDevTimeStamp[extDevName.indexOf(currDevId)]}
            />
          </MDBox>
        </MDBox>
      </MDBox>
    );
  }
  return <LoadingSpin />;
}

SelectDeviceList.defaultProps = {
  usrDeviceList: [{}],
};

SelectDeviceList.propTypes = {
  usrDeviceList: PropTypes.oneOfType([PropTypes.array, PropTypes.object]), // PropTypes.objectOf(PropTypes.array), //PropTypes.object
};

export default SelectDeviceList;
