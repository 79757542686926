global.currentPath = null;
global.currentDev = null;
global.fethDetails = false;
global.timeStamp = null;
global.IsSetDefaultGraph = true;
global.IsDownLoad = false;
global.selected = false;
global.usrRole = null;
global.baseurl = "https://emsbe.adroit-iiot.in/v1/";

global.thisUsrInfo = { isValid: false, uName: "", uRole: "", uCustomer: "" };
global.thisDeviceInfo = {};
