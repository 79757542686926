/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import useFetch from "layouts/dashboard/components/CustomRemoteApi/useFetch";
import LoadingSpin from "layouts/dashboard/components/LoadingSpin";

// import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import DeviceTableList from "./devicelist";

function flattenObject(customerName, list) {
  const TableData = [{}];

  list.forEach((location) => {
    location.substation.forEach((subStn) => {
      subStn.devices.forEach((dev) => {
        TableData.push({
          Customer: customerName,
          Location: location.name,
          Area: subStn.name,
          DeviceName: dev.name,
          DeviceID: dev.devid,
          Status: dev.linkstatus,
          LastActive: dev.lastactive,
        });
      });
    });
  });

  // console.log("TableData...", TableData);

  return TableData;
}

const status = (value) => (
  <MDBadge
    badgeContent={value === "0" ? "offline" : "online"}
    color={value === "0" ? "error" : "success"}
    variant="gradient"
    size="sm"
    circular
  />
);

function DeviceTree() {
  const remoteTableHeader = [{}];

  const url = global.baseurl;
  const delimiter = "/";
  const filler = "null";
  const endPoint = "get_user_resource_tree";

  const usr = localStorage.getItem("currentusr");

  // console.log("DeviceTree...url usr", url, usr);

  const resourceTreeApi = url.concat(usr, delimiter, filler, delimiter, endPoint);

  // GET DATA For Default Device...i.e. end device from the tree
  const { data: devList, loading, error } = useFetch(resourceTreeApi);

  if (devList)
    if (devList.length > 0) {
      const customerName = localStorage.getItem("customer");
      const TableData = flattenObject(customerName, devList);
      const TableDataSlice = TableData.slice(1, TableData.length);

      Object.keys(TableDataSlice[0]).forEach((el) => {
        // console.log("Change Size", el);
        if (el === "Status") {
          remoteTableHeader.push({
            header: el,
            accessorKey: el,
            align: "center",
            size: 20,
            Cell: ({ cell }) => status(cell.getValue()),
            muiTableHeadCellProps: {
              align: "center",
            },
            muiTableBodyCellProps: {
              align: "center",
            },
          });
        } else {
          remoteTableHeader.push({
            header: el,
            accessorKey: el,
            align: "center",
            size: 50,
            muiTableHeadCellProps: {
              align: "center",
            },
            muiTableBodyCellProps: {
              align: "center",
            },
          });
        }
      });

      const thisTableHeader = remoteTableHeader.slice(1, remoteTableHeader.length);

      // const handleSaveRow = async ({ exitEditingMode, row, values }) => {
      //   // if using flat data and simple accessorKeys/ids, you can just do a simple assignment here.
      //   tableData[row.index] = values;
      //   // send/receive api updates here
      //   setTableData([...tableData]);
      //   exitEditingMode(); // required to exit editing mode
      // };

      return (
        // <PageLayout image={bgImage} p={10} m={10}>
        <DashboardLayout>
          {/* <DashboardNavbar /> */}
          <MDBox
            flex
            // variant="gradient"
            bgColor="dark"
            borderRadius="lg"
            coloredShadow="info"
            p={1}
            mt={-1}
            width="78vw"
            height="95vh"
          >
            {loading && <LoadingSpin />}
            {error && <p>Server Error: {error.message}</p>}
            <DeviceTableList column={thisTableHeader} data={TableDataSlice} />
          </MDBox>
        </DashboardLayout>
      );
    }
  return null;
}
// function DeviceTree() {
//   // GET USR NAME FROM Sign-in Page
//   const [pathSelected, setSelectedPath] = useState(false);
//   const [usrDeviceTree, setUsrDeviceTree] = useState(null);

//   const url = global.baseurl;
//   const usr = localStorage.getItem("currentusr");
//   const delimiter = "/";
//   const filler = "null";
//   const urlEndPoint = "get_user_resource_tree";

//   const resourceTreeApi = url.concat(usr, delimiter, filler, delimiter, urlEndPoint);

//   const { data, loading, error } = useFetch(resourceTreeApi);

//   if (loading) return <LoadingSpin />;
//   if (error) return <p>Server Error: {error.message}</p>;

//   if (data)
//     if (data.length >= 1 && usrDeviceTree === null) {
//       setUsrDeviceTree(data);
//       global.currentPath = null;
//     }

//   // console.log("1 pathSelected", pathSelected, global.currentPath);

//   const showId = (path) => {
//     global.currentPath = path.concat("/");
//     setSelectedPath(true);
//     global.selected = true;
//     // console.log("2 pathSelected", pathSelected, global.currentPath);
//   };

//   // const slaveDevice = (h1, h2, h3, h4) => {
//   //   let path = "";
//   //   path = path.concat(h1, "/", h2, "/", h3, "/", h4);
//   //   // console.log("Source :: ", path);
//   //   if (global.currentPath == null) {
//   //     global.currentPath = path;
//   //   }
//   //   return (
//   //     <MDButton
//   //       borderradius="50px"
//   //       variant="gradient"
//   //       color="info"
//   //       size="small"
//   //       onClick={() => showId(path)}
//   //     >
//   //       {h4}
//   //     </MDButton>
//   //   );
//   // };

//   const deviceTree = (h1, h2, h3) => {
//     let path = "";
//     path = path.concat(h1, "/", h2, "/", h3);
//     // console.log("devices :: ", path);
//     if (global.currentPath == null) {
//       global.currentPath = path;
//     }
//     return (
//       <MDButton
//         borderradius="50px"
//         variant="gradient"
//         color="warning"
//         size="small"
//         onClick={() => showId(path)}
//       >
//         {h3}
//       </MDButton>
//     );
//   };

//   const renderELNodeTree = () => {
//     const result = null;
//     localStorage.setItem("currentPath", null);

//     if (usrDeviceTree)
//       if (usrDeviceTree.length > 0) {
//         return usrDeviceTree.map(
//           (location) => (
//             <TreeNode
//               key={location.name}
//               label={<MDBadge badgeContent={location.name} container />}
//             >
//               {location.substation
//                 ? location.substation.map((subStn) => (
//                     <TreeNode
//                       key={subStn.name}
//                       label={<MDBadge badgeContent={subStn.name} container />}
//                     >
//                       {subStn.devices
//                         ? subStn.devices.map((dev) => (
//                             <TreeNode
//                               key={dev.name}
//                               label={deviceTree(location.name, subStn.name, dev.name)}
//                             >
//                               {dev.sources
//                                 ? dev.sources.map(
//                                     (endPoint) => (
//                                       // endPoint.map((source) => (
//                                       <TreeNode
//                                         key={endPoint.name}
//                                         // label={slaveDevice(
//                                         //   location.name,
//                                         //   subStn.name,
//                                         //   dev.name,
//                                         //   endPoint.name
//                                         // )}
//                                         label={<MDBadge badgeContent={endPoint.name} container />}
//                                       />
//                                     )
//                                     // ))
//                                   )
//                                 : null}
//                             </TreeNode>
//                           ))
//                         : null}
//                     </TreeNode>
//                   ))
//                 : null}
//             </TreeNode>
//           )
//           // return (
//           //   <>
//           //     {/* parentTree */}
//           //     <TreeNode key={index} label={usrTree(usr.name)}>
//           //       {usr.location
//           //         ? usr.location.map((loc, index) => {
//           //             // console.log("2: location", loc.name);
//           //             return (
//           //               <TreeNode key={index} label={locationTree(loc.name)}>
//           //                 {loc.substation
//           //                   ? loc.substation.map((subStn, index) => {
//           //                       return (
//           //                         <TreeNode key={index} label={subStationTree(subStn.name)}>
//           //                           {subStn.devices
//           //                             ? subStn.devices.map((dev, index) => {
//           //                                 return (
//           //                                   <TreeNode key={index} label={deviceTree(dev.name)}>
//           //                                     {dev.slave
//           //                                       ? dev.slave.map((endPoint, index) => {
//           //                                           return <TreeNode key={index} label={endPoint} />;
//           //                                         })
//           //                                       : null}
//           //                                   </TreeNode>
//           //                                 );
//           //                               })
//           //                             : null}
//           //                         </TreeNode>
//           //                       );
//           //                     })
//           //                   : null}
//           //               </TreeNode>
//           //             );
//           //           })
//           //         : null}
//           //     </TreeNode>
//           //   </>
//           // );
//         );
//       }
//     return result;
//   };

//   // console.log("3 pathSelected", pathSelected, global.currentPath);

//   return (
//     <DashboardLayout>
//       {/* <DashboardNavbar /> */}
//       <Tree
//         lineWidth="2px"
//         lineHeight="40px"
//         lineColor="green"
//         lineBorderRadius="10px"
//         label={<MDBadge badgeContent={localStorage.getItem("currentusr")} container />}
//       >
//         {renderELNodeTree()}
//       </Tree>
//       {pathSelected === true && <GoToDashBoard />}
//     </DashboardLayout>
//   );
// }

// DeviceTree.defaultProps = {
//   treeData: [{}],
// };

// DeviceTree.propTypes = {
//   treeData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
// };

export default DeviceTree;
