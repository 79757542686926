/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Line } from "react-chartjs-2";

// @mui material components
// import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// DefaultLineChart configurations
import configs from "examples/Charts/LineCharts/DefaultLineChart/configs";

// Material Dashboard 2 React base styles
import colors from "assets/theme/base/colors";

function DefaultLineChart({ title, description, height, chart }) {
  const chartDatasets = chart.datasets
    ? chart.datasets.map((dataset) => ({
        ...dataset,
        tension: 0,
        pointRadius: 3,
        borderWidth: 4,
        backgroundColor: "transparent",
        fill: true,
        pointBackgroundColor: colors[dataset.color]
          ? colors[dataset.color || "dark"].main
          : colors.dark.main,
        borderColor: colors[dataset.color]
          ? colors[dataset.color || "dark"].main
          : colors.dark.main,
        maxBarThickness: 6,
      }))
    : [];

  const { data, options } = configs(chart.labels || [], chartDatasets);
  const renderChart = (
    <MDBox align="center" pt={1} px={1} bgColor="#f5f5f5" borderRadius="10px">
      <MDTypography variant="h6" textTransform="capitalize">
        {title}
      </MDTypography>
      {/* <MDTypography component="div" variant="button" color="dark" fontWeight="bold" align="center">
        {description}
      </MDTypography> */}
      <MDBox padding="1rem">
        {useMemo(
          () => (
            // <MDBox height={height} bgColor="#4682b4" borderRadius="lg">
            <MDBox height={height} borderRadius="lg" bgColor="#f5f5f5">
              <Line data={data} options={options} />
            </MDBox>
          ),
          [chart, height]
        )}
      </MDBox>
    </MDBox>

    // <MDBox py={1} pr={2} pl={icon.component ? 1 : 2}>
    //   {title || description ? (
    //     <MDBox display="flex" px={description ? 1 : 0} pt={description ? 1 : 0}>
    //       {icon.component && (
    //         <MDBox
    //           width="4rem"
    //           height="4rem"
    //           bgColor={icon.color || "info"}
    //           variant="gradient"
    //           coloredShadow={icon.color || "info"}
    //           borderRadius="xl"
    //           display="flex"
    //           justifyContent="center"
    //           alignItems="center"
    //           color="white"
    //           mt={-5}
    //           mr={2}
    //         >
    //           <Icon fontSize="medium">{icon.component}</Icon>
    //         </MDBox>
    //       )}
    //       <MDBox mt={icon.component ? -2 : 0}>
    //         {title && <MDTypography variant="h6">{title}</MDTypography>}
    //         <MDBox mb={1}>
    //           <MDTypography component="div" variant="button" color="text">
    //             {description}
    //           </MDTypography>
    //         </MDBox>
    //       </MDBox>
    //     </MDBox>
    //   ) : null}
    //   {useMemo(
    //     () => (
    //       <MDBox height={height} bgColor="info" borderRadius="lg">
    //         <Line data={data} options={options} />
    //       </MDBox>
    //     ),
    //     [chart, height]
    //   )}
    // </MDBox>
  );

  // return title || description ? <Card m={1}>{renderChart}</Card> : renderChart;
  return title || description ? (
    <MDBox bgColor="white" m={1} borderRadius="10px">
      {renderChart}
    </MDBox>
  ) : (
    renderChart
  );
}

// Setting default values for the props of DefaultLineChart
DefaultLineChart.defaultProps = {
  // icon: { color: "info", component: "" },
  title: "",
  description: "",
  height: "19rem",
};

// Typechecking props for the DefaultLineChart
DefaultLineChart.propTypes = {
  // icon: PropTypes.shape({
  //   color: PropTypes.oneOf([
  //     "primary",
  //     "secondary",
  //     "info",
  //     "success",
  //     "warning",
  //     "error",
  //     "light",
  //     "dark",
  //     "r_phase",
  //     "y_phase",
  //     "b_phase",
  //   ]),
  //   component: PropTypes.node,
  // }),
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.objectOf(PropTypes.array).isRequired,
};

export default DefaultLineChart;
