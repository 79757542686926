/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useState, useMemo } from "react";
// @mui material components
// import Icon from "@mui/material/Icon";

// @mui material components
// import Grid from "@mui/material/Grid";

// import { useTable } from "react-table";

// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableHead from "@mui/material/TableHead";

// import TableContainer from "@mui/material/TableContainer";
// import TableRow from "@mui/material/TableRow";
// import TableCell from "@mui/material/TableCell";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// import MDInput from "components/MDInput";
// import MDBadge from "components/MDBadge";
// import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
// import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
// import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";

// Data
// import authorsTableData from "layouts/tables/data/authorsTableData";
// import Axios from "axios";
import devInfoData from "./data/testData";

// const createData = (name, calories, fat, carbs, protein) => ({
//   id: name.replace(" ", "_"),
//   name,
//   calories,
//   fat,
//   carbs,
//   protein,
//   isEditMode: false,
// });

// const CustomTableCell = ({ row, name, onChange }) => {
//   // const classes = useStyles();
//   const { isEditMode } = row;
//   return (
//     <TableCell align="left">
//       {isEditMode ? (
//         <MDInput
//           value={row[name]}
//           name={name}
//           onChange={(e) => onChange(e, row)}
//           // className={classes.input}
//         />
//       ) : (
//         row[name]
//       )}
//     </TableCell>
//   );
// };

// function ManageDevice() {
//   const table = devInfoData();
//   const columns = useMemo(() => table.columns, [table]);
//   const data = useMemo(() => table.rows, [table]);

//   const tableInstance = useTable({ columns, data, initialState: { pageIndex: 0 } });

//   const { getTableProps, getTableBodyProps, headerGroups, prepareRow } = tableInstance;

//   const [rows, setRows] = useState([
//     createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
//     createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
//     createData("Eclair", 262, 16.0, 24, 6.0),
//   ]);
//   const [previous, setPrevious] = useState({}, []);

//   const onToggleEditMode = (id) => {
//     setRows((state) => {
//       return rows.map((row) => {
//         if (row.id === id) {
//           return { ...row, isEditMode: !row.isEditMode };
//         }
//         return row;
//       });
//     });
//   };

//   const onChange = (e, row) => {
//     if (!previous[row.id]) {
//       setPrevious((state) => ({ ...state, [row.id]: row }));
//     }
//     const value = e.target.value;
//     const name = e.target.name;
//     const { id } = row;
//     const newRows = rows.map((row) => {
//       if (row.id === id) {
//         return { ...row, [name]: value };
//       }
//       return row;
//     });
//     setRows(newRows);
//   };

//   const onRevert = (id) => {
//     const newRows = rows.map((row) => {
//       if (row.id === id) {
//         return previous[id] ? previous[id] : row;
//       }
//       return row;
//     });
//     setRows(newRows);
//     setPrevious((state) => {
//       delete state[id];
//       return state;
//     });
//     onToggleEditMode(id);
//   };

//   return (
//     <DashboardLayout>
//       <DashboardNavbar />
//       <TableContainer>
//         <Table aria-label="caption table">
//           {/* <TableHead>
//             <TableRow>
//               <TableCell align="left" />
//               <TableCell align="left">Dessert (100g serving)</TableCell>
//               <TableCell align="left">Calories</TableCell>
//               <TableCell align="left">Fat&nbsp;(g)</TableCell>
//               <TableCell align="left">Carbs&nbsp;(g)</TableCell>
//               <TableCell align="left">Protein&nbsp;(g)</TableCell>
//             </TableRow>
//           </TableHead> */}

//           <MDBox component="thead" bgColor="warning">
//             {headerGroups.map((headerGroup) => (
//               <TableRow {...headerGroup.getHeaderGroupProps()}>
//                 {headerGroup.headers.map((column) => (
//                   <DataTableHeadCell
//                     {...column.getHeaderProps()}
//                     width={column.width ? column.width : "auto"}
//                     align={column.align ? column.align : "left"}
//                   >
//                     {column.render("Header")}
//                   </DataTableHeadCell>
//                 ))}
//               </TableRow>
//             ))}
//           </MDBox>

//           <TableBody>
//             {rows.map((row) => (
//               <TableRow key={row.id}>
//                 <TableCell>
//                   {row.isEditMode ? (
//                     <>
//                       <Icon aria-label="done" onClick={() => onToggleEditMode(row.id)}>
//                         done
//                       </Icon>
//                       <Icon aria-label="revert" onClick={() => onRevert(row.id)}>
//                         reload
//                       </Icon>
//                     </>
//                   ) : (
//                     <Icon aria-label="delete" onClick={() => onToggleEditMode(row.id)}>
//                       edit
//                     </Icon>
//                   )}
//                 </TableCell>
//                 <CustomTableCell {...{ row, name: "name", onChange }} />
//                 <CustomTableCell {...{ row, name: "calories", onChange }} />
//                 <CustomTableCell {...{ row, name: "fat", onChange }} />
//                 <CustomTableCell {...{ row, name: "carbs", onChange }} />
//                 <CustomTableCell {...{ row, name: "protein", onChange }} />
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </DashboardLayout>
//   );
// }
function ManageDevice() {
  // const GroupedcolumnsTest = [
  //   {
  //     Header: "Device Info",
  //     // align: "center",
  //     columns: [
  //       { Header: "Device ID", accessor: "id", align: "center" },
  //       { Header: "Status", accessor: "status", align: "center" },
  //     ],
  //   },
  //   {
  //     Header: "Mobile Numbers",
  //     // align: "center",
  //     // colSpan: 2,
  //     columns: [
  //       { Header: "Mobile_1", accessor: "num_1", align: "center" },
  //       { Header: "Mobile_2", accessor: "num_2", align: "center" },
  //     ],
  //   },
  //   {
  //     Header: "Threshold",
  //     // align: "center",
  //     // colSpan: 2,
  //     columns: [
  //       { Header: "Power Factor", accessor: "pf", align: "center" },
  //       { Header: "Max Demand", accessor: "md", align: "center" },
  //     ],
  //   },
  //   { Header: "action", accessor: "action", align: "center" },
  // ];

  const { columns: testCol, rows: testRow } = devInfoData();

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox pb={3}>
        <DataTable
          table={{ columns: testCol, rows: testRow }}
          isSorted={false}
          entriesPerPage={false}
          showTotalEntries={false}
          noEndBorder
        />
        {global.usrRole === "admin" ? <MDButton> EDIT </MDButton> : null}
      </MDBox>
    </DashboardLayout>
  );
}

// function ManageDevice() {
//   // const { columns, rows } = authorsTableData();

//   const devInfoHeader = [
//     { Header: "Client", accessor: "clientID", align: "center" },
//     { Header: "Device", accessor: "devName", align: "center" },
//     { Header: "LastActive", accessor: "timeStamp", align: "center" },
//     { Header: "Status", accessor: "status", align: "center" },
//     { Header: "OperatorName", accessor: "operatorName", align: "left" },
//     { Header: "APN Name", accessor: "apnName", align: "left" },
//     { Header: "APN ID", accessor: "apnID", align: "left" },
//     { Header: "APN Password", accessor: "apnPass", align: "left" },
//   ];

//   const [devInfoData, setDeviceInfo] = useState([{}]);

//   const rDevInfo = async () => {
//     try {
//       const response = await Axios.get("http://localhost:3001/devInfo");

//       // console.log(response.data);

//       setDeviceInfo(response.data);

//       // console.log("remote", devInfoData);
//     } catch (err) {
//       // Handle Error Here
//       // eslint-disable-next-line
//       console.error(err);
//     }
//   };

//   return (
//     <DashboardLayout>
//       <DashboardNavbar />
//       <MDBox pb={3}>
//         <Grid container spacing={6}>
//           <Grid item xs={12}>
//             <MDBox
//               color="white"
//               bgColor="info"
//               variant="gradient"
//               borderRadius="50px"
//               shadow="lg"
//               opacity={1}
//               p={1}
//               align="center"
//               mb="30px"
//             >
//               <MDTypography align="center" variant="h4" color="white">
//                 Device Configuration
//               </MDTypography>
//             </MDBox>
//             <MDBox align="center" mt="10px" mr="8rem">
//               <MDButton
//                 borderRadius="50px"
//                 variant="gradient"
//                 color="info"
//                 size="small"
//                 onClick={() => rDevInfo()}
//               >
//                 SHOW
//               </MDButton>
//               <MDButton borderRadius="50px" variant="gradient" color="info" size="small">
//                 ADD
//               </MDButton>
//               <MDButton borderRadius="50px" variant="gradient" color="info" size="small">
//                 Edit
//               </MDButton>
//               <MDButton borderRadius="50px" variant="gradient" color="info" size="small">
//                 Delete
//               </MDButton>
//             </MDBox>
//             <Grid mt="20px" display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={1}>
//               <MDBox
//                 mr="4rem"
//                 gridColumn="span 5"
//                 color="white"
//                 bgColor="warning"
//                 variant="gradient"
//                 align="center"
//                 borderRadius="50px"
//               >
//                 General Configuration
//               </MDBox>
//               <MDBox
//                 ml="10rem"
//                 gridColumn="span 6"
//                 color="white"
//                 bgColor="warning"
//                 variant="gradient"
//                 align="center"
//                 borderRadius="50px"
//               >
//                 Network Configuration
//               </MDBox>
//             </Grid>
//             <MDBox>
//               <DataTable
//                 table={{ columns: devInfoHeader, rows: devInfoData }}
//                 isSorted={false}
//                 entriesPerPage={false}
//                 showTotalEntries={false}
//                 noEndBorder
//               />
//             </MDBox>
//           </Grid>
//         </Grid>
//       </MDBox>
//     </DashboardLayout>
//   );
// }

export default ManageDevice;
