import React from "react";

import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import PopulateParam from "./populateparam";
import PopulateGraph from "./populategraph";

function DeviceDetails() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        {global.fethDetails === true ? (
          <Grid flex container>
            {/* PARAM box */}
            <Grid item xs={3}>
              <MDBox flex>
                <PopulateParam />
              </MDBox>
            </Grid>
            <Grid item xs={9}>
              <MDBox flex>
                <PopulateGraph />
              </MDBox>
            </Grid>
          </Grid>
        ) : (
          <MDBox>
            <MDTypography>Select Device From Dashboard</MDTypography>
          </MDBox>
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default DeviceDetails;
